<template>
  <v-row align='center' justify='center' class='text-center'>
    <v-btn class='ml-4'
      ><download-excel
        class='btn btn-default'
        :data='list1'
        :fields='json_fields'
        worksheet='My Worksheet'
        name='ข้อมูลสรุปรายงานผู้ใช้งาน.xls'
        header='รายงานผู้ใช้งาน'
      >
        <img src='@/assets/Excel-Logo.png' width='50px' />
        Download รายงานผู้ใช้งาน
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
export default {
  props: ['list1'],
  data() {
    return {
      json_fields: {
        'ชื่อ': 'requestNumber',
        'อีเมลล์': 'email',
        'หน่วยงานปฏิบัติงาน': 'adminCompanyName',
        'จังหวัด': 'serviceProvince',
        'อำเภอ': 'serviceDistrict',
        'ตำบล': 'serviceSubDistrict',
        'สถานะ': 'requestProvince',
        'ประเภทผู้ใช้งาน': 'userType',
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ],
      list1: []
    }
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log('data', response.data.data[0]);
    //   this.list1 = response.data.data[0];
    // },
  }
}
</script>
